<template>
  <div style="height: 100%">
    <div align="center" class="px-5 background sub-header">
      <div class="d-flex align-center" align="center">
        <div style="padding-left: 0px; padding-right: 0px">
          <v-btn
            @click="onBackAction"
            icon
            plain
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </div>
        <v-col cols="1" style="max-width: 84px">
          <v-avatar color="admiraGreen" size="60">
            <v-icon style="font-size: 31px" dark
              >mdi-record-circle-outline</v-icon
            >
          </v-avatar>
        </v-col>

        <v-col cols style="min-width: 400px" class="ml-auto">
          <v-row
            ><p class="text--text" style="font-size: 14px; margin: 0px">
              Grabación
            </p></v-row
          >
          <v-row class="mt-4">
            <h4
              class="text-truncate pr-2 text--text"
              style="
                font-size: 26px;
                font-weight: 300;
                text-align: left;
                line-height: 32px;
              "
            >
              {{ this.actualSpot.spotCode }} - {{ this.actualSpot.name }}
            </h4>
          </v-row>
        </v-col>
        <Connections />
        <v-col cols style="max-width: max-content" class="ml-auto mr-6">
          <v-row class=""
            ><p style="font-size: 14px; margin: 0px" class="text--text">
              Estudio
            </p></v-row
          >
          <v-row class="mt-4">
            <v-chip
              class="py-4 px-5"
              color="#926CB1"
              text-color="white"
              style="font-size: 14px; border-radius: 22px"
            >
              ESTUDIO {{ studio }}
            </v-chip>
          </v-row>
        </v-col>
      </div>
    </div>
    <div class="d-flex" style="height: 100%">
      <div
        class="d-flex full-width"
        style="padding: 20px; gap: 12px; width: 100%"
      >
        <v-col
          v-if="audios.editAudio == false"
          class="ma-0 pa-0"
          :class="
            mediaType === 'MEGAFONIA' || mediaType === 'RADIO'
              ? 'spotText-editor'
              : 'ghost-spotText-editor'
          "
          style="position: relative"
        >
          <vue-editor
            ref="quillEditor"
            :value="textSpotSpeaker"
            :editorToolbar="customToolbar"
            class="box-text editor"
            @text-change="changingTextSpotTech"
            :disabled="isRecording == true || this.actualSpot?.session == true"
            @focus="editingText = true"
            @blur="editingText = false"
            style="height: 80%"
          />
          <v-btn
            class="justify-center admiraGreen"
            rounded
            depressed
            small
            dark
            @click="saveText"
            :disabled="this.actualSpot?.session == true"
          >
            Guardar Texto
          </v-btn>
          <v-overlay v-if="roleChangingTxt === 'slave_plus' || roleChangingTxt === 'slave_plus_remote'" absolute>
            <v-row class="fill-height" align-content="center" justify="center">
              <v-col class="text-subtitle-1 text-center" cols="12">
                El Creativo está escribiendo
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                  color="admiraGreen"
                  indeterminate
                  buffer-value="0"
                  stream
                  reverse
                  height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-overlay>
        </v-col>
        <AudioMaster
          v-if="(mediaType === 'MEGAFONIA' || mediaType === 'RADIO') && showRecorder == true"
          :key="audioMasterKey"
          ref="audioMaster"
          :audios="audios"
          :audioStatus="actualStatus"
          :updateAudioStatus="updateAudioStatus"
          :dataspot="dataspot"
          :falseSpot="falseSpot"
          :langToMp3="langToMp3"
          :sendDatatoback="sendDatatoback"
          :fromSession="fromSession"
          :goBack="goBack"
        />
      </div>

      <div v-if="audios.editAudio == false" style="position: relative;">
        <RecordingInfo class="recording-info"/>
        <v-btn class="toggle-btn" @click.stop="drawer = !drawer" color="admiraGreen">
          <v-icon>
            mdi mdi-information
          </v-icon>
        </v-btn>
      </div>
    </div>

    <v-overlay color="background" opacity="1" absolute :value="showOverlay">
      <div v-if="showReturnMessage == false">
        <h3 class="text--primary">Por favor espere</h3>
        <p class="text--primary">Cargando audio</p>
        <v-chip
          :color="connectedToTechnician ? '#749948' : 'disabled'"
          class="py-4 px-5 mb-4"
          text-color="white"
          style="font-size: 14px; border-radius: 22px"
        >
          <v-icon small left>
            {{
              connectedToTechnician ? "mdi-eye-outline " : "mdi-eye-off-outline"
            }}
          </v-icon>
          Técnico
        </v-chip>
        <v-progress-linear
          color="admiraGreen"
          indeterminate
          rounded
          height="7"
        ></v-progress-linear>
      </div>

      <div v-else>
        <h3 class="text--primary">No se ha encontrado el archivo de audio</h3>
        <v-btn class="justify-center admiraGreen mt-5" rounded depressed small dark width="" @click="onBackAction">Volver a la
        lista
        </v-btn>
      </div>
    </v-overlay>
    <FloatingPanel
      v-show="this.actualSpot?.session != true && audios.editAudio == false"
      :setSpot="setSpot"
      :falseSpot="falseSpot"
      :goBack="goBack"
      :isRecording="audios.isRecording"
      :editAudio="audios.editAudio"
      :textSpotSpeaker="textSpotSpeaker"
      :auxTextSpot="auxTextSpot"
      :saveText="saveText"
      :editingText="editingText"
    />
    <v-navigation-drawer v-model="drawer"
          absolute
          right
          temporary>
          <RecordingInfo/>
        </v-navigation-drawer>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { VueEditor, Quill } from "vue2-editor";

const Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block);

export default {
  components: {
    VueEditor,
    Connections: defineAsyncComponent(() =>
      import("../components/Connections.vue")
    ),
    AudioMaster: defineAsyncComponent(() =>
      import("../components/AudioMaster.vue")
    ),
    FloatingPanel: defineAsyncComponent(() =>
      import("../components/FloatingPanel.vue")
    ),
    RecordingInfo: defineAsyncComponent(() =>
      import("../components/RecordingInfo.vue")
    ),
  },
  data() {
    return {
      audioMasterKey: 0,
      actualStatus: "",
      audios: {
        masterBuffer: [],
        encodedBuffer: [],
        editAudio: false,
        loadingAudio: true,
        isRecording: false,
      },
      criLang: "",
      dataspot: null,
      mediaType: "",
      nameLang: "",
      loadingAudio: true,
      showRecorder: false,
      urlput: "",
      falseSpotUrlPut: "",
      customToolbar: [["link", "underline", "italic", "bold"]],
      isRecording: false,
      editingText: false,
      langToMp3: null,
      fromSession: false,
      drawer: false,
      showReturnMessage: false,
      hasMaster:false,
    };
  },
  computed: {
    ...mapState([
      "actualSpot",
      "actualActivity",
      "connectedToTechnician",
      "port",
      "studio",
      "textSpotSpeaker",
      "roleChangingTxt",
      "role",
      "listSpots",
      "updateSpotList",
      "tabSelected"
    ]),

    falseSpot() {
      return this.actualSpot?.idAdmira == undefined;
    },
    showOverlay() {
      return this.audios.loadingAudio == true && this.showRecorder == false
    }
  },
  methods: {
    ...mapMutations(["setTextSpotSpeaker"]),
    ...mapActions(["sendTextSpot"]),

    setSpot() {
      this.clearPreviousResources(); // Limpia los recursos previos
      this.audioMasterKey += 1; 
      this.audios = {
        masterBuffer: [],
        encodedBuffer: [],
        editAudio: false,
        loadingAudio: true,
        isRecording: false,
      },
      this.showRecorder = false
      const {
        mediaType,
        spotCode: code,
        taskId,
        mediaId,
        texts,
          recordingStatus,
      } = this.actualSpot;

      this.audios.loadingAudio = true;

      this.mediaType = mediaType;
      this.portsend = this.port;

      this.createDataSaveSpot({ mediaType, code, taskId, mediaId, texts });
      this.currentSpot = this.actualSpot.spotCode;


      // if (this.actualSpot.texts.criteria?.id === undefined) {
      //   this.audios.loadingAudio = false;
      // }
      this.actualStatus = recordingStatus;
      // this.setCriId(texts.criteria.id);
      this.criLang = texts.criteria.id;
      this.nameLang = texts.criteria.name;

      this.$globalData.socket.emit("statusShowingRecorder", true);
      this.status = "pending encoding";
      this.recordingDate = Date.now();
      this.dataspot = {
        status: this.status,
        studio: this.idStudio,
        duration: this.duration,
        recordingDate: null,
      };
      this.auxTextSpot = this.actualSpot.texts.text;
      this.setTextSpotSpeaker(this.actualSpot.texts.text);

      if (this.mediaType == "MEGAFONIA") {
        this.obtenerData(this.criLang, this.port).then((data) => {
          this.langToMp3 = data
          this.sendDatatoback(this.langToMp3);
          this.setAudioInfo()
        });
      } else if (this.mediaType == "RADIO") {
        this.sendDatatoback(null);
        this.setAudioInfo()
      } else {
        this.audios.loadingAudio = false;
      }
    },
    clearPreviousResources() {
      if (this.audioContext) {
        this.audioContext.close();
        this.audioContext = null;
      }
      this.audios.masterBuffer = [];
      this.audios.encodedBuffer = [];
    },
    sendDatatoback(langtomp3) {
      const sinHtml = this.deleteHTML(this.textSpotSpeaker);
      const audioMaster = this.$refs.audioMaster
      const dataToSend = {
        lufs: audioMaster == undefined ? null : audioMaster.lufs,
        limit: audioMaster == undefined ? null : audioMaster.limit,
        currentSpot: this.actualSpot.idAdmira == undefined
            ? this.actualSpot.spotCode
            : this.actualSpot.realSpotCode,
        criLang:
          this.mediaType !== "MEGAFONIA"
            ? ""
            : this.langToMp3,
        mediatypesend: this.mediaType,
        radiodest: this.actualSpot.centerDestination,
        radiotext: sinHtml,
        codeid: this.actualSpot.spotCode
      };
      window.ipcRenderer.send("normalize-audio-data", dataToSend);
    },

    async obtenerData(langvariable, portsend) {
      try {
        let response = await this.$axios.get(
          `/api/v1/spots/langCode/${langvariable}`
        );

        return response.data.data;
      } catch (error) {
        console.error(`Hubo un error al obtener los datos: ${error.message}`);
        return null;
      }
    },
    deleteHTML(texto) {
      return texto.replace(/<[^>]+>/g, "");
    },

    async setAudioInfo() {
      //Asignar la URL
      this.urlput = `/api/v1/spots/${this.actualSpot._id}/recording/`;

      this.setAudioStatus();
    },
    async updateAudioStatus(payload) {
      try {
        const response = await this.$axios.put(this.urlput, payload);
        this.actualStatus = response.data.data.status;
        this.setRecordings()
      } catch (error) {
        console.log(error);
      }
    },

    async setAudioStatus() {
      //Definir el estatus y obtener el audio basado en ello
      switch (this.actualStatus) {
        case "empty":
          this.textstatus = "Vacio";
          this.audios.loadingAudio = false;
          this.showRecorder = true;
          break;
        case "edited pending encoding":
        case "pending encoding":
        case "encoded":
          this.checkAudioMaster();
          break;
        case "encoded another session":
          this.checkAudioEncoded();
          break;
      }
    },

    checkAudioMaster() {
      this.hasMaster = false
      window.ipcRenderer.send("checkfilexist");
      window.ipcRenderer.receiveOnce("file-response", async (data) => {

        if(data !== null) this.hasMaster = true
        
        await this.checkfiles(data);
        this.checkAudioEncoded();
      });
    },
    checkAudioEncoded() {
      window.ipcRenderer.send("checkencodedfile");
      window.ipcRenderer.receiveOnce("file-response-encoded", async (data) => {
        if(data == null && this.actualSpot?.session == true && this.tabSelected == 0 && this.hasMaster == false) {
          this.showReturnMessage = true
          return
        }
        await this.checkfiles(data);
        this.audios.loadingAudio = false;
        this.showRecorder = true;
        this.$refs.audioMaster.setAudio();
      });
    },
    async checkfiles(data) {
      if (data) {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        try {
          const audioBuffer = await audioContext.decodeAudioData(
            new Uint8Array(data.buffer).buffer
          );
          if (data.type == 0) {
            this.audios.masterBuffer.push(audioBuffer);
          } else {
            this.audios.encodedBuffer.push(audioBuffer);
          }

          //console.log('MP3 file loaded and converted to AudioBuffer:', audioBuffer);
        } catch (error) {
          console.error(
            "Error decodificando el archivo de audio checkfile:",
            error
          );
        }
      } else {
        console.error("No se ha recibido datos del archivo.");
      }
    },

    changingTextSpotTech() {
      if (this.roleChangingTxt !== "slave_plus" && this.role !== "slave_plus" && this.roleChangingTxt !== 'slave_plus_remote') {
        if (this.firstEntryEditorT) {
          this.$nextTick(() => {
            if (this.$refs.quillEditor.quill.root.innerHTML) {
              this.debounceTextSpot(
                this.$refs.quillEditor.quill.root.innerHTML
              );
            }
          });
        } else {
          this.firstEntryEditorT = true;
        }
      }
    },
    async saveText() {
      const data = {
        ...this.dataSaveSpot,
        text: this.$refs.quillEditor.quill.root.innerHTML,
      };
      this.auxTextSpot = this.$refs.quillEditor.quill.root.innerHTML;
      const response = await this.sendTextSpot(data);
      if (response.status == 200) {
        this.$store.dispatch("manageSuccess", "Texto de cuña actualizado");
        this.setRecordings();
      }
    },
    debounceTextSpot(text) {
      this.$globalData.socket.emit("textChangedBy", {
        toNamespace: "ECI_ESTUDIO",
        toRoom: `estudio${this.studio}`,
        data: this.role,
      });
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$globalData.socket.emit("textRecording", {
          toNamespace: "ECI_ESTUDIO",
          toRoom: `estudio${this.studio}`,
          data: text,
        });
        this.$globalData.socket.emit("textChangedBy", {
          toNamespace: "ECI_ESTUDIO",
          toRoom: `estudio${this.studio}`,
          data: "",
        });
      }, 650);
    },
    createDataSaveSpot(data) {
      if (this.actualSpot?.session == true) return;
      this.dataSaveSpot = data;
      this.dataSaveSpot.criLang = data.texts.criteria.id;
      this.dataSaveSpot.taskId = this.actualActivity.taskId;
      this.$globalData.socket.emit("saveDataSpot", data);
    },
    async setRecordings() {
      for (let index = 0; index < this.listSpots.length; index++) {
        const spot = this.listSpots[index];
        if (spot._id == this.actualSpot._id) {
          this.listSpots[index].texts.text =
            this.$refs.quillEditor.quill.root.innerHTML;
          this.listSpots[index].recordingStatus = this.actualStatus
        }
      }
      this.$store.state.updateSpotList = false;
    },

    goBack() {
      this.$store.state.actualSpot = null;
      if (this.isPlaying == true) {
        this.pauseAudio();
      }
      this.$globalData.socket.emit("getAllowCitation", {
        toNamespace: "ECI_ESTUDIO",
        toRoom: `estudio${this.studio}`,
        data: { acces: false, recording: null },
      });
      if (this.$store.state.path == "citationDetails") {
        this.$router.push({
          name: "citationDetails",
        });
      } else {
        this.$router.push({
          name: "sessions",
          params: { from: "showRecording" },
        });
      }
    },
    checkChanges() {
      this.$refs.audioMaster.checkChanges();
    },
    onBackAction() {
      if(this.audios.isRecording == true) return
      if(this.audios.editAudio == true) {
        this.checkChanges()
      }
      else {
        this.goBack()
      }
    }
  },
  watch: {
    roleChangingTxt(newValue, oldValue) {
      if (
        newValue !== oldValue &&
        this.roleChangingTxt &&
        this.roleChangingTxt !== this.role
      ) {
        if (this.role === "slave_plus")
          this.$refs.quillEditorPlus.quill.root.blur();
        else if (this.role === "technician")
          this.$refs.quillEditor.quill.root.blur();
      }
    },
    updateSpotList() {
      if(this.updateSpotList == true) {
        this.saveText()
      }
    },
    textSpotSpeaker() {
      this.auxTextSpot = this.textSpotSpeaker
    }
  },
  created() {
    if(this.$route.params.sessions == true) {
      this.fromSession = true
    }
    this.setSpot();
  },
  beforeDestroy() {
    if (this.debounce) clearTimeout(this.debounce);

    if(this.fromSession== false) {
      this.$refs.quillEditor.quill.root.blur();
    }
    this.$globalData.socket.emit("getAllowCitation", {
      toNamespace: "ECI_ESTUDIO",
      toRoom: `estudio${this.studio}`,
      data: { acces: false, recording: null },
    });
    this.$globalData.socket.emit("statusShowingRecorder", false);

    this.setTextSpotSpeaker("");
    this.modifiedAudioBuffers = [];
  },
};
</script>

<style>
.editor {
  height: calc(100vh);
}
/* #reader-slave .ql-container {
    font-size: inherit
} */
.ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button:focus,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button:hover {
  color: var(--v-admiraGreen-base) !important;
}

.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter {
  stroke: var(--v-admiraGreen-base) !important;
}

.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill {
  fill: var(--v-admiraGreen-base) !important;
}

.ql-snow a {
  color: var(--v-admiraGreen-base) !important;
}

.ql-editor {
  color: #777;
  padding: 15px 20px 20px 20px !important;
  /* max-height: calc(100vh - 300px); */
}

.ql-container.ql-snow {
  border: 0 !important;
  border-radius: 0 0 25px 25px;
  background: white;
}

.ql-toolbar.ql-snow {
  border: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 25px 25px 0 0;
  background: #f5f5f5;
}
.spotText-editor {
  height: calc(100vh - 164px);
}

.toggle-btn {
  display: none;
  position: absolute;
  width: 50px;
  left: -90px;
  bottom: 130px;
  padding: 0;
}

@media only screen and (max-width: 1199px) {
  .toggle-btn {
    display: block;
  }
  .recording-info {
    display: none;
  }
}

</style>