import axios from 'axios';
import store from '../store'; // Asegúrate de que la ruta sea correcta

const config = {
  baseURL: `${process.env.VUE_APP_URL_API}`, // Cambia según tus necesidades
};

const _axios = axios.create(config);

// Función para configurar el proxy dinámicamente
export function setProxy(proxyConfig) {
  if (proxyConfig) {
    _axios.defaults.proxy = proxyConfig; // Configura el proxy en la instancia personalizada
    console.log(`Proxy configurado: ${JSON.stringify(proxyConfig)}`);
  } else {
    _axios.defaults.proxy = false; // Deshabilita el proxy si no hay configuración
    console.log('Proxy deshabilitado.');
  }
}

// Interceptor para solicitudes
_axios.interceptors.request.use(
  async (request) => {
    const isLoginEndpoint = ['/login', '/loginApp', '/refreshToken'].some((endpoint) =>
      request.url.includes(endpoint)
    );

    if (!isLoginEndpoint) {
      // Verifica y renueva el token si es necesario
      const token = await store.dispatch('checkToken', request);
      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }
    }

    return request;
  },
  (error) => Promise.reject(error)
);

// Interceptor para respuestas
_axios.interceptors.response.use(
  (response) => {
    // Manejo de mensajes de éxito basado en el método y la URL
    if (response.config.method === 'post' &&
        !response.config.url.includes('receipts') &&
        !response.config.url.includes('auth') &&
        !response.config.url.includes('spots') &&
        !response.config.url.includes('tasks')) {
      store.dispatch('manageSuccess', 'Se ha guardado correctamente.');
    } else if (response.config.method === 'put' &&
               !response.config.url.includes('lock') &&
               !response.config.url.includes('medias') &&
               !response.config.url.includes('receipts')) {
      store.dispatch('manageSuccess', 'Se ha actualizado correctamente.');
    } else if (response.config.method === 'delete' &&
               !response.config.url.includes('receipts') &&
               !response.config.url.includes('medias')) {
      store.dispatch('manageSuccess', 'Se ha eliminado correctamente.');
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
      const isLoginEndpoint = ['/login', '/loginApp', '/refreshToken'].some((endpoint) =>
        originalRequest.url.includes(endpoint)
      );

      if (!isLoginEndpoint) {
        try {
          // Intenta renovar el token
          await store.dispatch('refreshToken');
          originalRequest.headers.Authorization = `Bearer ${store.state.token}`;
          return _axios(originalRequest); // Reintenta la solicitud original
        } catch (refreshError) {
          console.error('Error al renovar el token:', refreshError);
          throw refreshError; // Lanza el error para manejarlo en la UI
        }
      }
    }

    // Manejo de errores genéricos
    store.dispatch('manageError', 'Ha ocurrido un error.');
    return Promise.reject(error);
  }
);

export default _axios;

// Plugin para Vue.js (si lo necesitas en el frontend)
export const axiosPlugin = {
  install(Vue) {
    Vue.prototype.$axios = _axios; // Registra la instancia globalmente
  },
};
